export enum AxiosErrorType {
  BAD_REQUEST = "ERR_BAD_REQUEST",
  UNAUTHORIZED = "ERR_UNAUTHROZIED",
  BAD_RESPONSE = "ERR_BAD_RESPONSE",
  NETWORK_ERROR = "ERR_NETWORK"
}

export enum LogMessageType {
  USER_REGISTER = "User Registered",
  USER_LOGIN = "User Logged In", 
  ORDER_CREATE = "Order Created",
  ORDER_START = "Order Started", 
  ORDER_STOP = "Order Stoped",
  ORDER_CANCEL = "Order Cancelled",
  ORDER_APPROVE = "Order Approved",
  ORDER_SAVE = "Order Saved",
  LICENSE_ACCEPTED = "License Accepted"
}
